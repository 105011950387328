import React from 'react';
import { IonAlert, IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, StrongText, Subtitle } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { informationCircleOutline, lockClosed } from 'ionicons/icons';
import './index.css';
import { VoucherItem } from '../../screens/loyalty';
import { withRouter } from 'react-router';
import { buyReward, getUnlockRewardsInfo } from '../../store/actions';
import { getConfig } from '../../appConfig';
import moment from 'moment';
import { isDefined, validateProfileData } from '../../lib/utils';
import { QRCodeCanvas } from 'qrcode.react';
import ValidateButton from '../validateButton';

const RewardItem = ({ reward, id, __, action, available_balance }) => {
	const rewardLocked = reward?.cost > available_balance
	return (
		<div onClick={() => rewardLocked ? () => { } : action(id)} className={`loyalty-reward ${rewardLocked ? 'loyalty-reward-locked' : ''}`} style={{ backgroundImage: `url(${reward?.image})` }}>
			<div className="loyalty-reward-name">
				<StrongText className="block">{`${reward?.cost} ${__('points')}`}</StrongText>
				<SmallText>{reward?.name}</SmallText>
			</div>
			{rewardLocked &&
				<IonIcon size='large' color='white' icon={lockClosed} />
			}

		</div>
	);
};

class UnlockRewardItmes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedReward: null,
			buyRewardModalOpen: false,
			rewardInfoModalOpen: false,
		};
	}
	componentDidMount() {
		this.props.dispatch(getUnlockRewardsInfo());
	}
	buyReward = (rewardId) => {
		this.props.dispatch(buyReward({ rewardId, fromOrder: this.props?.location?.state?.fromOrder ? true : false }));
	};
	viewReward = (id) => {
		const { rewards } = this.props;
		this.setState({ selectedReward: rewards.find((r) => r.id === id) });
	};

	render() {
		const { __, available_balance, rewards, unlockRewardsInfo, auth, profile } = this.props;
		const { selectedReward, rewardInfoModalOpen } = this.state;
		const isAuth = auth.loggedIn;
		const valid = validateProfileData(profile).isValid;
		return (
			<>
				<>
					<div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => this.setState({ rewardInfoModalOpen: true })}>
						<Subtitle className="loyalty-heading primary-color">{__('Unlock rewards')}</Subtitle>
						<IonIcon
							style={{ cursor: 'pointer', height: '25px', width: '25px' }}
							color="primary"
							onClick={() => {
								this.setState({ rewardInfoModalOpen: true });
							}}
							icon={informationCircleOutline}
						/>
					</div>

					<div className="loyalty-rewards-wrapper">
						{(rewards || []).map((reward, index) => {
							return <RewardItem key={'rw-' + index} {...reward} __={__} available_balance={available_balance} action={() => this.viewReward(reward.id)} />;
						})}
					</div>
				</>
				<Modal
					showBackArrow
					className="voucher-modal unlock-rewards-modal"
					isOpen={selectedReward !== null}
					onDidDismiss={() => {
						this.setState({ selectedReward: null });
						this.props.history.replace({ state: {} });
					}}
				>
					{selectedReward && selectedReward.reward ? (
						<div style={{ textAlign: 'center' }}>
							<Title>{__('Redemption details')}</Title>
							<VoucherItem onlyImage {...selectedReward} __={__} />
							<Spacer size={1} />
							{getConfig().flags.hasReedemRewards ? <>
								<Subtitle className="bold">{selectedReward.name}</Subtitle>
								<NormalText>{`${selectedReward.stamps_required} ${__('points required')}`}</NormalText>

								<Spacer size={1} />
								<div className="voucher-modal-content">{selectedReward.reward.description ? <div dangerouslySetInnerHTML={{ __html: selectedReward.reward.description }} /> : null}</div>
								<Spacer size={1} />

								<IonButton
									disabled={available_balance < selectedReward.stamps_required}
									onClick={() => this.setState({ buyRewardModalOpen: true })}
									style={{ margin: 0 }}
									expand="block"
									color="primary"
								>
									{available_balance < selectedReward.stamps_required && <IonIcon style={{ height: '15px', width: '15px', marginRight: '5px' }} icon={lockClosed} />}

									{`${__('Redeem for')} ${selectedReward.stamps_required} ${__('points')}`}
								</IonButton>
								<Spacer size={1} />
								<SmallText className="block centered">
									{__('You have')} {available_balance} {__('points')}
								</SmallText>
							</>
								:
								<div className="reward-modal-content">
									{selectedReward.reward.name || selectedReward.reward.reward.name ? (
										<>
											<Subtitle className=" bold">
												{selectedReward.reward.name || selectedReward.reward.reward.name}
											</Subtitle>
											<Spacer size={1} />
										</>
									) : null}
									{selectedReward.reward.small_print || selectedReward.reward.reward.small_print ? (
										<div>
											<NormalText>
												{selectedReward.reward.small_print ||
													selectedReward.reward.reward.small_print}
											</NormalText>
										</div>
									) : null}
									{selectedReward.reward.expiry_date || selectedReward.reward.reward.expiry_date ? (
										<div>
											<NormalText>
												{__('Expires:')}{' '}
												{moment(
													selectedReward.reward.expiry_date ||
													selectedReward.reward.reward.expiry_date,
													'YYYY/MM/DD',
												).format('DD/MM/YY')}
											</NormalText>
										</div>
									) : null}
									<Spacer size={1} />
									<div onClick={() => forwardTo('/terms')}>
										<NormalText className="bold">{__('Terms & Conditions')}</NormalText>
									</div>
									<Spacer size={1} />
									{selectedReward.reward.description || selectedReward.reward.reward.description ? (
										<div>
											<SmallText>
												{__(
													selectedReward.reward.description ||
													selectedReward.reward.reward.description,
												)}
											</SmallText>
										</div>
									) : null}
									<>
										<Spacer />
										{isAuth && valid ? (
											isDefined(profile.qr_code) ? (
												<div className="qr-holder">
													<QRCodeCanvas value={profile.qr_code} size={150} />
												</div>
											) : (
												<div>
													<h5>{__('NO QR CODE')}</h5>
												</div>
											)
										) : (
											<ValidateButton />
										)}
										<Spacer />
									</>
								</div>
							}

						</div>
					) : null}
				</Modal>
				<IonAlert
					isOpen={this.state.buyRewardModalOpen}
					onDidDismiss={() => this.setState({ buyRewardModalOpen: false })}
					header={__('Are you sure?')}
					message={`${__('Are you sure you wish to redeem')} ${selectedReward?.stamps_required} ${__('points in exchange for the following voucher:')} ${selectedReward?.name}`}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: __('Redeem'),
							handler: () => this.buyReward(selectedReward?.id),
						},
					]}
				/>
				<Modal
					className="loyalty-faq-modal"
					isOpen={rewardInfoModalOpen}
					onDidDismiss={() => {
						this.setState({ rewardInfoModalOpen: false });
					}}
				>
					<div className="lefted">
						<Title>{__('Unlock Rewards')}</Title>
						<div dangerouslySetInnerHTML={{ __html: unlockRewardsInfo }}></div>
					</div>{' '}
				</Modal>
			</>
		);
	}
}
const stateToProps = (state) => {
	const { profile, auth } = state.profile;
	const { history } = state.orders;

	return {
		history,
		vouchers: state.profile.vouchers || [],
		qr_code: state.profile.profile.qr_code,
		profile,
		auth,
		unlockRewardsInfo: state.common.unlockRewardsInfo,
	};
};
export default connect(stateToProps)(withRouter(withTranslation(UnlockRewardItmes)));
