import React, { Component } from 'react';
import { connect } from 'react-redux';

import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { getTermsAndConditions } from '../../store/actions';
import NoData from '../../components/noData';

import './index.css';
import { Title } from '../../components/common';
import { forwardTo, goBack } from '../../lib/utils';

class Terms extends Component {
	componentDidMount() {
		this.props.dispatch(getTermsAndConditions());
	}
	backHandler = () => {
		if (this.props.location?.state?.fromRegisterPage) {
			forwardTo('/register', { saveRegisterData: true });
		} else {
			goBack();
		}
	};
	render() {
		const { __, terms } = this.props;

		return (
			<Loading>
				<Layout showHamburger={false} backHandler={this.backHandler} color="transparent" headerTitle={__('Terms & conditions')}>
					<div className='page-content'>
						<Title>{__('Terms & conditions')}</Title>
						{terms ? <div dangerouslySetInnerHTML={{ __html: terms }} /> : <NoData />}
					</div>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	return {
		terms: state.common.terms || null,
	};
};

export default connect(stateToProps)(withTranslation(Terms));
