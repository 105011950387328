import axios from 'axios';
import { isDefined } from './utils';
import { getConfig } from '../appConfig';
import { Capacitor } from '@capacitor/core';
import { App as AppCap } from '@capacitor/app';
import packageInfo from '../../package.json';

let axiosInstance;

const baseURL = getConfig().api.baseURL;
const wordPressBaseURL = getConfig().api.wordPressBaseURL;
const nativeWallet = getConfig().api.nativeWallet;
const addToGoogleWallet = `${nativeWallet}/google-wallet`;
const addToAppleWallet = `${nativeWallet}/apple-wallet`;
const endpoints = {
  login: '/api-token-auth', //post
  register: '/me/register', //post
  getProfile: '/me', //get
  changePassword: '/me', //put
  changeProfile: '/me', //put
  resetPassword: '/me/reset_password', //put
  deleteCard: '/delete_payment_card', //put
  sendFeedback: '/feedback/create', //post,
  firebaseToken: '/firebase-token', //post,
  appVersion: '/appversion', //get,
  social: '/social', //get,
  restaurants: '/restaurants/all', //get
  terms: '/terms', //get,
  privacyPolicy: '/privacy', //get
  faq: '/faq', //get
  rewards: '/reward_milestones', //get
  history: '/transactions/history', //get
  ikentooMenu: '/ikentoo_menu/', //get
  ikentooMenusForLocation: '/ikentoo_menus/', //get
  getPaymentCards: '/orders/list_payment_cards', //post
  addPaymentCard: '/orders/create_payment_card', //post
  removePaymentCard: '/orders/delete_payment_card', //post
  createOrder: '/order/create', //post
  orderHistory: '/me/orders', //get
  validateEmail: '/me/validate_email', //get
  sendVoucherCode: '/voucher/code', //put,
  referFriend: '/referred/add_friend', //post
  getVouchers: '/me/vouchers', //get
  publicStripeKey: '/public_stripe_key', //get
  frontEndAppConfig: '/front_end_app_config', //get
  getTranslations: '/translation_json', //get
  addDeliveryAdress: '/me/add_new_address', //put
  getRestaurantSnoozeData: '/ikentoo_menu/get_snooze_data', //get
  postCodeCheck: '/order/postcode_check', //post
  getNearestLocation: '/order/get_nearest_restaurant_location', //put
  getDeliveryRangeType: '/get_delivery_range_type', //get
  locationCodeCheck: '/order/location_code_check', // post
  addPickupPoint: '/me/add_pickup_point', // put,
  getOrderProduction: '/order_production_length',
  removeDeliveryAddress: '/orders/remove_delivery_address', //post
  cancelOrder: '/orders/cancel_order', // post
  getDefaultMenuId: '/get_default_menu_id', //get
  getDefaultMenu: '/get_default_menu', //get
  saveJudoPayCard: '/orders/save_judo_pay_cards', //post
  checkDiscount: '/order/check_discount', //post
  getLastOrder: '/orders/get_last_order', // get
  paymentInfo: '/update_payment_info',
  subscriptionPackages: '/subscription/packages', // get
  createSubscription: '/subscription/create_stripe_subscription', // post
  createSubscriptionNative: '/subscription/create_stripe_subscriptio_native', // post
  cancelSubscription: '/subscription/cancel', // put
  payMembership: '/subscription/memberships', // post
  sendGiftVoucher: '/gift_voucher/create',
  resendGiftVoucher: '/gift_voucher/resend',
  redeemGiftVoucher: '/gift_voucher/redeem_code',
  getSentGiftVouchers: '/gift_voucher/sent_gift_vouchers',
  updateGiftVoucher: '/gift_voucher/update',
  checkGiftVoucherBalance: '/gift_voucher/check_balance',
  getNews: '/news',
  nutritionData: '/nutrition_data',
  client: '/client', //get,
  createStripeOrder: '/order/create_stripe_order', //post
  updateStripeOrder: '/order/update_stripe_order', //post
  removeProfile: '/me/delete_user', // post
  createStripeOrderNative: '/order/create_stripe_order_native',
  confirmPaymentIntentNative: '/order/confirm_payment_intent_native',
  updateProfileImage: '/me/update_profile_image',
  createYocoOrder: '/order/create_yoco_order', //post
  buyReward: '/reward/buy',
	refreshScanToken: '/refresh_scan_token',
	unlockRewardsInfo: '/unlock_rewards_info',

};

const createAxiosInstance = (token) => {
  const headers = isDefined(token)
    ? {
      Authorization: 'JWT ' + token,
    }
    : {};
  axiosInstance = axios.create({
    headers,
    timeout: 50000,
  });
  return axiosInstance;
};

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName]);

export const getErrorMessage = (fieldName, errors = {}) =>
  !isFieldValid(fieldName, errors) ? errors[fieldName] : '';

const getClientId = async () => {
  let clientId = null;
  if (Capacitor.isNativePlatform()) {
    const info = await AppCap.getInfo();
    const appVersionFromDevice = info.version;
    clientId = parseInt(appVersionFromDevice.split('.')[0]);
  }
  return clientId;
};
const api = {
  login: async (username, password, socialAuth) => {
    const response = await createAxiosInstance().post(
      baseURL +
      `${endpoints.login}${Capacitor.isNativePlatform() ? `?isWeb=true` : ''
      }`,
      {
        username,
        password,
        socialAuth,
        client_id: await getClientId(),
        version: packageInfo.version,
      },
    );
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  register: async (userData) => {
    userData.client_id = await getClientId();
    const response = await createAxiosInstance().post(
      baseURL +
      `${endpoints.register}${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`,
      { ...userData, version: packageInfo.version }
    );
    axiosInstance = createAxiosInstance(response.data.token);
    return response;
  },
  logout: () => {
    // eslint-disable-next-line no-console
    console.log('logout');
  },
  resetPassword: async (email) =>
    await axiosInstance.put(baseURL + endpoints.resetPassword + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      email,
      client_id: await getClientId(),
      version: packageInfo.version,
    }),
  getProfile: async () =>
    await axiosInstance.get(
      baseURL +
      endpoints.getProfile +
      `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
    ),
  updateProfile: async (data = {}) =>
    await axiosInstance.put(baseURL + endpoints.changeProfile + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      client_id: await getClientId(),
      version: packageInfo.version,
    }),
  sendFirebaseToken: async (data) =>
    await axiosInstance.post(baseURL + endpoints.firebaseToken + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      client_id: await getClientId(),
      version: packageInfo.version,
    }),
  getAppVersion: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.appVersion}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data?.min_front_end_version),
  getSocialLinks: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.social}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.social_link_json),
  sendFeedback: async (data = {}) =>
    await axiosInstance.post(baseURL + endpoints.sendFeedback + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  getRestaurants: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.restaurants}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  getTerms: async (locale, client_name) =>
    axios
      .create()
      .get(baseURL + '/' + locale + '/terms/' + client_name + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,)
      .then((res) => res.data),
  getPrivacyPolicy: async (locale, client_name) =>
    axios
      .create()
      .get(baseURL + '/' + locale + '/privacy/' + client_name + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`)
      .then((res) => res.data),
  getFaq: async (locale, client_name) =>
    axios
      .create()
      .get(baseURL + '/' + locale + '/faqs/' + client_name + `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`)
      .then((res) => res.data),


  getRewards: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.rewards}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  getIkenooMenu: async (menuId, businessLocationId) =>
    axiosInstance
      .get(
        baseURL +
        endpoints.ikentooMenu +
        menuId +
        '/location/' +
        businessLocationId +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  getIkentooMenusForLocation: async (businessLocationId) =>
    axiosInstance
      .get(
        baseURL +
        endpoints.ikentooMenusForLocation +
        'location/' +
        businessLocationId +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  getHistory: async (page) =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.history}${page ? `/${page}` : ''}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data),
  getPaymentCards: async () =>
    axiosInstance
      .post(
        baseURL +
        endpoints.getPaymentCards +
        `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`,
        {
          client_id: await getClientId(),
          version: packageInfo.version,
        }
      )
      .then((res) => res.data.data),
  addPaymentCard: async (cardData) =>
    axiosInstance
      .post(baseURL + endpoints.addPaymentCard + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...cardData, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data.data),
  removePaymentCard: async (cardData) =>
    axiosInstance
      .post(baseURL + endpoints.removePaymentCard + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...cardData, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  createOrder: async (orderData) =>
    axiosInstance
      .post(baseURL + endpoints.createOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...orderData, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  getOrderHistory: async (page) =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.orderHistory}${page ? `/${page}` : ''}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data),
  sendCode: async (data) =>
    await axiosInstance
      .put(baseURL + endpoints.sendVoucherCode + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  sendRefer: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.referFriend + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  getVouchers: async () =>
    await axiosInstance
      .get(
        baseURL +
        `${endpoints.getVouchers}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data),
  validateEmail: async () =>
    await axiosInstance.get(
      baseURL +
      `${endpoints.validateEmail}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
      }`,
    ),
  getPublicStripeKey: async () =>
    axiosInstance
      .get(
        baseURL +
        endpoints.publicStripeKey +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data.public_stripe_key),
  getFrontEndAppConfig: async () => {
    return axiosInstance
      .get(
        baseURL +
        `${endpoints.frontEndAppConfig}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data);
  },
  getClient: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.client}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data),
  // getTranslations: () => axiosInstance.get(baseURL + endpoints.getTranslations).then(res => res.data.data.translation_json),
  getTranslations: () =>
    axiosInstance
      .get(`${wordPressBaseURL}/en/dictionary/${getConfig().envs.APP_DOMAIN}?version=${packageInfo.version}`)
      .then((res) => res.data),
  addDeliveryAdress: async (data) =>
    axiosInstance.put(baseURL + endpoints.addDeliveryAdress + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  getRestaurantSnoozeData: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.getRestaurantSnoozeData}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data),
  postCodeCheck: async (data) =>
    axiosInstance.post(baseURL + endpoints.postCodeCheck + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  getNearestLocation: async (data) =>
    axiosInstance.put(baseURL + endpoints.getNearestLocation + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  getDeliveryRangeType: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.getDeliveryRangeType}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data.delivery_range_type),
  locationCodeCheck: async (data) =>
    axiosInstance.post(baseURL + endpoints.locationCodeCheck + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  addPickupPoint: async (data) =>
    axiosInstance.put(baseURL + endpoints.addPickupPoint + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  getOrderProduction: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.getOrderProduction}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data.order_production_mins),
  removeDeliveryAddress: async (postalCode) =>
    axiosInstance
      .post(baseURL + endpoints.removeDeliveryAddress + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
        ...postalCode,
        version: packageInfo.version,
        client_id: await getClientId(),
      })
      .then((res) => res.data),
  cancelOrder: async (data) =>
    axiosInstance
      .post(baseURL + endpoints.cancelOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data.data),
  getDefaultMenuId: async () =>
    axiosInstance
      .get(
        baseURL +
        `${endpoints.getDefaultMenuId}?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''
        }`,
      )
      .then((res) => res.data.data.default_menu_id),
  getDefaultMenu: async (menuId) =>
    axiosInstance
      .get(
        baseURL +
        endpoints.getDefaultMenu +
        '/' +
        menuId +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  saveJudoPayCard: async (cardData) =>
    axiosInstance
      .post(baseURL + endpoints.saveJudoPayCard + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...cardData, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data.data),
  checkDiscount: async (data) =>
    await axiosInstance.post(baseURL + endpoints.checkDiscount + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  getLastOrder: async (id) =>
    axiosInstance
      .get(
        baseURL +
        endpoints.getLastOrder +
        '/' +
        id +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  updatePaymentInfo: async (paymentInfo = {}) =>
    await axiosInstance
      .post(baseURL + endpoints.paymentInfo + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...paymentInfo, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data.data),
  getSubscriptions: async () =>
    await axiosInstance.get(
      baseURL +
      endpoints.subscriptionPackages +
      `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
    ),
  createSubscription: async (data) =>
    await axiosInstance.post(baseURL + endpoints.createSubscription + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  createSubscriptionNative: async (data) =>
    await axiosInstance.post(baseURL + endpoints.createSubscriptionNative + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  cancelUserSubscription: async (data) =>
    axiosInstance.put(baseURL + endpoints.cancelSubscription + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...data,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  payMembership: async (membership) =>
    axiosInstance.post(baseURL + endpoints.payMembership + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, {
      ...membership,
      version: packageInfo.version,
      client_id: await getClientId(),
    }),
  sendGiftVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.sendGiftVoucher + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  resendGiftVoucher: async (data) =>
    await axiosInstance
      .put(baseURL + endpoints.resendGiftVoucher + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  updateGiftVoucher: async (data) =>
    await axiosInstance
      .put(baseURL + endpoints.updateGiftVoucher + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  redeemGiftVoucher: async (data) =>
    await axiosInstance
      .post(baseURL + endpoints.redeemGiftVoucher + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  getSentGiftVouchers: async () =>
    axiosInstance
      .get(
        baseURL +
        endpoints.getSentGiftVouchers +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data),


  checkGiftVoucherBalance: async (data) => {
    return axiosInstance.post(baseURL + endpoints.checkGiftVoucherBalance + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() }).then((res) => res.data);
  },
  getNews: async () =>
    axiosInstance
      .get(
        baseURL +
        endpoints.getNews +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data),
  getNutritonData: async () =>
    await axiosInstance
      .get(
        baseURL +
        endpoints.nutritionData +
        `?version=${packageInfo.version}${Capacitor.isNativePlatform() ? `&isWeb=true&client_id=${await getClientId()}` : ''}`,
      )
      .then((res) => res.data.data),
  addToGoogleWallet: async (data) =>
    await axiosInstance
      .post(addToGoogleWallet + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, appversion: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  addToAppleWallet: async (data) =>
    await axiosInstance
      .post(addToAppleWallet + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, appversion: packageInfo.version, client_id: await getClientId() }, { responseType: 'blob' })
      .then((res) => res.data),
  createStripeOrder: async (orderData) => axiosInstance.post(baseURL + endpoints.createStripeOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...orderData, version: packageInfo.version, client_id: await getClientId() }).then((res) => res.data),
  updateStripeOrder: async (orderId) => axiosInstance.post(baseURL + endpoints.updateStripeOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...orderId, version: packageInfo.version, client_id: await getClientId() }).then((res) => res.data),
  removeProfile: async (data) => await axiosInstance.post(baseURL + endpoints.removeProfile + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() }),
  createStripeOrderNative: async (orderData) =>
    axiosInstance
      .post(baseURL + endpoints.createStripeOrderNative + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...orderData, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  confirmPaymentIntentNative: async (data) =>
    axiosInstance
      .post(baseURL + endpoints.confirmPaymentIntentNative + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...data, version: packageInfo.version, client_id: await getClientId() })
      .then((res) => res.data),
  updateProfileImage: async (data) => await axiosInstance.post(baseURL + endpoints.updateProfileImage, data),
  createYocoOrder: async (orderData) =>
    axiosInstance
      .post(baseURL + endpoints.createYocoOrder + `${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`, { ...orderData, version: packageInfo.version, client_id: await getClientId(), isWeb: Capacitor.isNativePlatform() ? true : null })
      .then((res) => res.data),
  buyReward: async (rewardId) => await axiosInstance.put(`${baseURL}${endpoints.buyReward}/${rewardId}${Capacitor.isNativePlatform() ? `?isWeb=true` : ''}`).then((res) => res.data),
  getUnlockRewardsInfo: async () => await axiosInstance.get(baseURL + endpoints.unlockRewardsInfo + `${Capacitor.isNativePlatform() ? `?isWeb=true&client_id=${await getClientId()}` : ''}`).then((res) => res.data),
	getMilestoneRewardsInfo: async () => await axiosInstance.get(baseURL + endpoints.milestoneRewardsInfo + `${Capacitor.isNativePlatform() ? `?isWeb=true&client_id=${await getClientId()}` : ''}`).then((res) => res.data),
	getLoyaltyInfo: async () => await axiosInstance.get(baseURL + endpoints.loyaltyInfo + `${Capacitor.isNativePlatform() ? `?isWeb=true&client_id=${await getClientId()}`: ''}`).then((res) => res.data),
	refreshScanToken: async () => {
		return axiosInstance.get(baseURL + endpoints.refreshScanToken + `${Capacitor.isNativePlatform() ? `?isWeb=true&client_id=${await getClientId()}` : ''}`).then((res) => res.data.data);
	},
};

api.createAxiosInstance = createAxiosInstance;

export default { ...api };
