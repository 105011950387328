import { loginRequest, registerRequest, setFullRegisterForm, showToast } from '../../store/actions';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { IonButton, IonIcon, isPlatform } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import { logoApple } from 'ionicons/icons';
import { connect } from 'react-redux';
import React from 'react';
import { registerPlugin } from '@capacitor/core';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import './index.css';

const isWeb = () => Capacitor.getPlatform() === 'web';
const isAndroid = () => Capacitor.getPlatform() === 'android';
const CustomAppleLogin = registerPlugin('CustomAppleLogin');      

class SocialLogin extends React.Component {
  constructor(props) {
    super(props);
    this.googleButtonRef = React.createRef();
    this.state = {
      googleButtonWidth: null,
    };
  }

  async componentDidMount() {
    if(isAndroid()){
      GoogleAuth.initialize();
    }
    
    setTimeout(() => {
      if (this.googleButtonRef.current) {
        this.setState({ googleButtonWidth: `${this.googleButtonRef.current.clientWidth}px` });
      }
    }, 200);
  }

  async googleSignInNative() {
    const { __, dispatch, validateForm, isRegister, protectedReferrer, registerFormData } = this.props;
    const isFormValid = validateForm ? validateForm(registerFormData) : true;
    try {
      if(isFormValid){
        const googleResponse = await GoogleAuth.signIn();
        const credential = googleResponse?.authentication?.idToken;
        if (credential) {
          if (!googleResponse.error) {
            if (isRegister) {
              dispatch(setFullRegisterForm(registerFormData));
              dispatch(registerRequest({ referrer: '/register', socialAuth: { tokenId: credential, authType: 'Google' } }));
            } else {
              dispatch(loginRequest({ username: '', referrer: protectedReferrer, socialAuth: { tokenId: credential, authType: 'Google' } }));
            }
          } else {
            dispatch(showToast(__('Error logging in with Google!'), 'warning'));
          }
        }
      }
    } catch (error) {
      console.error('Google login error: ', error)
    } 
  }
  
  onSuccess(response) {
    const { credential } = response;
    const { __, dispatch, validateForm, isRegister, protectedReferrer, registerFormData } = this.props;
    const isFormValid = validateForm ? validateForm(registerFormData) : true;
    if (isFormValid) {
      if (!response.error) {
        if (isRegister) {
          dispatch(setFullRegisterForm(registerFormData));
          dispatch(registerRequest({ referrer: '/register', socialAuth: { tokenId: credential, authType: 'Google' } }));
        } else {
          dispatch(loginRequest({ username: '', referrer: protectedReferrer, socialAuth: { tokenId: credential, authType: 'Google' } }));
        }
      } else {
        dispatch(showToast(__('Error logging in with Google!'), 'warning'));
      }
    }
  }

  async onAppleSuccess() {
		const { __, dispatch, validateForm, isRegister, protectedReferrer, registerFormData } = this.props;
		const isFormValid = validateForm ? validateForm(registerFormData) : true;
		if (isFormValid) {
			let provider = new firebase.auth.OAuthProvider('apple.com');
			const response = await firebase.auth().signInWithPopup(provider);
			if (!response.error && response?.credential) {
				const id_token = response.credential.idToken;
				const code = response.credential.accessToken;
        if(response?.user?.displayName){
          const displayName = response.user.displayName.split(" ");
          registerFormData.first_name = displayName[0] || "";
          registerFormData.last_name = displayName[1] || "";
        }
        if(response?.user?.email){
					registerFormData.email = response?.user?.email || "";
				}
				if (isRegister) {
					dispatch(setFullRegisterForm(registerFormData));
					dispatch(registerRequest({ referrer: '/register', socialAuth: { tokenId: id_token, code: code, authType: 'Apple' } }));
				} else {
					dispatch(loginRequest({ username: '', referrer: protectedReferrer, socialAuth: { tokenId: id_token, code: code, authType: 'Apple' } }));
				}
			} else {
				dispatch(showToast(__('Error logging in with Apple!'), 'warning'));
			}
		} else {
			dispatch(showToast(__('Invalid form for Apple login!'), 'warning'));
		}
	}

  async onAppleNativeSuccess() {
    const { __, dispatch, validateForm, isRegister, protectedReferrer, registerFormData } = this.props;
    const isFormValid = validateForm ? validateForm(registerFormData) : true;
    if (isFormValid) { 
      CustomAppleLogin.addListener('signInResult', (response) => {
        if (!response.error) {        
          const id_token = response.identityToken;
          const code = response.authorizationCode;
          if(response?.givenName){
            registerFormData.first_name = response?.givenName;
          }
          if(response?.familyName){
            registerFormData.last_name = response?.familyName;
          }
          if(response?.email){
            registerFormData.email = response?.email;
          }
          dispatch(setFullRegisterForm(registerFormData));
          dispatch(loginRequest({ username: '', referrer: protectedReferrer, socialAuth: { tokenId: id_token, code: code, authType: 'Apple' } }));
  
        } else {
          dispatch(showToast(__('Error logging in with Apple!'), 'warning'));
        }
      });
      CustomAppleLogin.addListener('signUpResult', (response) => {
        if (!response.error) {        
          const id_token = response.identityToken;
          const code = response.authorizationCode;
          if(response?.givenName){
            registerFormData.first_name = response?.givenName;
          }
          if(response?.familyName){
            registerFormData.last_name = response?.familyName;
          }
          if(response?.email){
            registerFormData.email = response?.email;
          }
          dispatch(setFullRegisterForm(registerFormData));
          dispatch(registerRequest({ referrer: '/register', socialAuth: { tokenId: id_token, code: code, authType: 'Apple' }}));
        } else {
          dispatch(showToast(__('Error registering with Apple!'), 'warning'));
        }
      });
      CustomAppleLogin.initiateCustomAppleLogin({registerRequest: isRegister});
      return null
    }else {
			dispatch(showToast(__('Invalid form for Apple login!'), 'warning'));
    }
  }

  render() {
    const { googleClientId, appleClientId, redirectUrl, testGoogleClientId, testAppleClientId } = getConfig()?.socialLogin || {};
    const { __, onGoogleSuccess, onGoogleFailure, onAppleSuccess, isRegister } = this.props;
    const { googleButtonWidth } = this.state;
    const showGoogleNativeButton = isAndroid();
    const showGoogleWebButton = (testGoogleClientId || googleClientId) && googleButtonWidth && isWeb();

    return (
      <div ref={this.googleButtonRef}>
        {showGoogleNativeButton ? (
          <GoogleNativeLoginButton isRegister={isRegister} onClick={async() => this.googleSignInNative()}/>
        ) : null }
        {showGoogleWebButton ? (
          <GoogleOAuthProvider clientId={testGoogleClientId || googleClientId || ''}>
            <div className="google-login-button" expand="block">
              <GoogleLogin
                onSuccess={(response) => (onGoogleSuccess ? onGoogleSuccess(response) : this.onSuccess(response))}
                onFailure={(response) => (onGoogleFailure ? onGoogleFailure(response) : this.onSuccess(response))}
                cookiePolicy="single_host_origin"
                shape="circle"
                context="signin"
                logo_alignment="center"
                type="standard"
                text={isRegister ? 'signup_with' : 'signin_with'}
                theme="outline"
                size="large"
                width={googleButtonWidth || '1000px'}
              />
            </div>
          </GoogleOAuthProvider>
        ) : null }
        {(testAppleClientId || appleClientId) && isPlatform('ios') && (
          <IonButton expand="block" color="black" className="round-button" onClick={() => (isWeb() ? this.onAppleSuccess() : this.onAppleNativeSuccess())}>
            <IonIcon slot="start" icon={logoApple} /> {isRegister ? 'Sign up with Apple' : 'Sign in with Apple'}
          </IonButton>
        )}
      </div>
    );
  }
}

const stateToProps = (state) => {
  const { registerFormData } = state.profile;
  return {
    registerFormData,
  };
};
 
class GoogleNativeLoginButton extends React.Component {
  render(){
    const isRegister = this.props.isRegister;
    return(
      <button className="gsi-material-button" onClick={this.props.onClick}>
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: "block" }}>
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span className="gsi-material-button-contents">{isRegister ? 'Sign up with Google' : 'Sign in with Google'}</span>
          <span style={{ display: "none" }}>{isRegister ? 'Sign up with Google' : 'Sign in with Google'}</span>
        </div>
      </button>
    )
  }
}
export default connect(stateToProps)(withTranslation(SocialLogin));

