import { IonLabel, IonList, IonListHeader } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NormalText, SmallText } from '../../components/common';
import NoData from '../../components/noData';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { isDefined } from '../../lib/utils';
import './index.css';

class orderList extends Component {
	itemList = (__, subCategoryItems, i) => {
		const { profile, showModal } = this.props;

		return (subCategoryItems || []).map((item, index) => {
			const selectedResturant = Basket.getRestaurant();
			let itemIsDisabled = false;
			if(selectedResturant && selectedResturant.disabled_skus && selectedResturant.disabled_skus.length > 0){
				itemIsDisabled = selectedResturant.disabled_skus.includes(item.sku);
			}
			if (item.sku === undefined || itemIsDisabled) {
				return <></>;
			}
			let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
			if (image && image.indexOf('http://') !== -1) {
				image = image.replace(/http:\/\//g, 'https://');
			}
			const name = __(Basket.getProductName(item, profile));
			return (
				<div
					key={item.sku + '_' + index + i}
					title={name}
					className={`order-list-item  ${!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''} `}
					onClick={() => (Basket.isProductUnsnoozed(item) ? showModal(item) : null)}
				>
					<div
						className="item-image"
						style={image && image !== '' ? { backgroundImage: `url(${image})` } : { backgroundSize: 'contain', backgroundImage: `url(${this.props.clientProfile.logo_image})` }}
					/>
					<div className="item-bar">
						<div className="item-name ellipsis">
							<NormalText className="primary-color">{name}</NormalText>
						</div>
						<SmallText>{Basket.formatPrice(Basket.getProductCardPrice(item),true)}</SmallText>
					</div>
				</div>
			);
		});
	};

	drawCategoryItems = (__, category, items, breadCrumb = null, i, isGroup = false, allGroup = false) => {
		let name = category.name ? breadCrumb + category.name : breadCrumb + '';
		let drawSubCategory = isGroup ? (
			<div className="order-sublist-holder" ref={this.props.menuRefs[`${name}_${i}`]}>
				<IonListHeader className={allGroup ? 'order-sublist-header' : 'order-sublist-header empty'}>
					<IonLabel>{name}</IonLabel>
				</IonListHeader>
				<div className="order-list-grid">{this.subCategoriesList(__, category, breadCrumb + category.name)}</div>
			</div>
		) : (
			<>{items}</>
		);

		// const orderListItems = document.querySelectorAll('.order-list-item')
		// if (orderListItems) {
		// 	orderListItems.forEach(item => {
		// 		if (item.parentElement) {
		// 			item.parentElement.className = 'order-list-grid'
		// 		}
		// 	})
		// }
		return <React.Fragment key={category.type + '_' + name + i}>{drawSubCategory}</React.Fragment>;
	};

	breadCrumb = (name) => {
		let breadCrumb = name !== '' ? name + ' | ' : name;
		return breadCrumb;
	};

	subCategoriesList = (__, category, name) => {
		if (!isDefined(category)) {
			return <NoData />;
		}
		const categoryItems = category.menuEntry || category.menuEntryGroups || null;
		let items = this.itemList(__, categoryItems);
		let breadCrumb = this.breadCrumb(name);
		if (categoryItems) {
			return categoryItems.map((subCategory, index) => {
				if (subCategory['@type'] === 'group') {
					items = this.itemList(__, subCategory.menuEntry, index);
					const allGroup = subCategory.menuEntry.some((item) => item.type !== 'group');
					return this.drawCategoryItems(__, subCategory, items, breadCrumb, index, true, allGroup);
				} else {
					items = this.itemList(__, [subCategory], index);
					return this.drawCategoryItems(__, subCategory, items, name, index, false);
				}
			});
		}
		return this.drawCategoryItems(__, category, items, name, 0);
	};

	calcHeight = () => {
		const { category } = this.props;
		let height = 0;
		if (this.ionList && this.orderListItems && isDefined(category)) {
			let orderListHeight = window.innerHeight - this.props.categoryHeight - 20;
			let lastCategory = this.ionList.childNodes[this.ionList.childNodes.length - 1];
			if (lastCategory && lastCategory.clientHeight < orderListHeight) {
				let lastCategoryHeader = lastCategory.childNodes[0];
				let lastCategoryWrap = lastCategory.childNodes[lastCategory.childNodes.length - 1];
				let lastCategoryWrapItem = lastCategoryWrap.childNodes[lastCategoryWrap.childNodes.length - 1];
				let lastCategoryMargin = parseInt(window.getComputedStyle(lastCategory).marginBottom);
				if (orderListHeight && lastCategoryHeader && lastCategoryWrapItem) {
					height = orderListHeight - lastCategoryWrap.clientHeight - lastCategoryMargin - lastCategoryHeader.clientHeight;
				}
			}
		}

		return height;
	};

	render() {
		const { __, category } = this.props;
		return (
			<div className="order-list-items" ref={(ref) => (this.orderListItems = ref)}>
				<IonList lines="none" ref={(ref) => (this.ionList = ref)}>
					{this.subCategoriesList(__, category, '')}
				</IonList>
				<div style={{ height: this.calcHeight() }}></div>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		profile: store.profile.profile,
		clientProfile: store.common.clientProfile,
	};
};

export default connect(mapStateToProps)(withTranslation(orderList));
